<template>
  <div>
    <v-container fluid>
      <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
        <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
      <v-toolbar flat dense class="tc-title">
        <v-toolbar-title>Pay Admission Fees</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
      <br />
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
      <div v-else>
        <v-card v-if="provisionadmission">
          <v-card-text>
            Email :<span class="text">{{ provisionadmission.email }},</span>
            &nbsp;&nbsp;&nbsp; Name :<span class="text"
              >{{
                provisionadmission.fullname_as_per_previous_marksheet
              }},</span
            >&nbsp;&nbsp;&nbsp; Programme Name :<span class="text"
              >{{ program }},</span
            >&nbsp;&nbsp;&nbsp; Programme Year :<span class="text"
              >{{ year }},</span
            >&nbsp;&nbsp;&nbsp; Academic Year :<span class="text"
              >{{ academicyear }},</span
            >
            &nbsp;&nbsp;&nbsp; Category :<span class="text"
              >{{ category }}.</span
            >&nbsp;&nbsp;&nbsp;
            <hr style="margin-top: 10px; margin-bottom: 10px" color="orange" />
            <center style="font-size: 16px !important">
              Payable Amount :
              <span class="text" style="font-size: 16px !important">{{
                payable_amount
              }}</span>
            </center>
          </v-card-text>
        </v-card>
        <br />
        <v-alert type="alert" prominent v-if="provisionadmission">
          If your transaction is completed (Money Deducted from your Account)
          and Receipt is not generated Please contact VU
          Admission(admission.provisional@vupune.ac.in). Do not try to Pay fees
          again.
          <br />Please note down your Reference number is
          <span style="font-size: 18px; font-weight: 700"
            >{{ provisionadmission.erp_transaction_id }}.</span
          >
        </v-alert>
        <v-card
          class="mx-auto"
          max-width="100%"
          outlined
          style="padding-left: 10px; padding-right: 10px; border: 1px solid"
        >
          <span style="color: red !important">
            <v-checkbox
              v-model="checkbox"
              color="red"
              name="checkbox"
              id="allowcheckbox"
              checked="false"
              :label="'I have read and agree to the Terms and Conditions and Privacy Policys'"
              @change="checkterms(checkbox)"
            ></v-checkbox>
          </span>
        </v-card><br/>
        <v-row>
          <v-col cols="4" sm="4"></v-col>
          <v-col cols="12" sm="2" v-if="this.payu">
            <form :action="pu_url" method="post">
              <input type="hidden" name="firstname" :value="pdg.firstname" />
              <input type="hidden" name="lastname" :value="pdg.lastname" />
              <input type="hidden" name="surl" :value="pdg.surl" />
              <input type="hidden" name="phone" :value="pdg.phone" />
              <input type="hidden" name="key" :value="pdg.key" />
              <input type="hidden" name="hash" :value="pdg.hash" />
              <input type="hidden" name="curl" :value="pdg.curl" />
              <input type="hidden" name="furl" :value="pdg.furl" />
              <input type="hidden" name="txnid" :value="pdg.txnid" />
              <input
                type="hidden"
                name="productinfo"
                :value="pdg.productinfo"
              />
              <input type="hidden" name="udf1" :value="pdg.udf1" />
              <input type="hidden" name="udf2" :value="pdg.udf2" />
              <input type="hidden" name="udf3" :value="pdg.udf3" />
              <input type="hidden" name="udf4" :value="pdg.udf4" />
              <input type="hidden" name="udf5" :value="pdg.udf5" />
              <input type="hidden" name="drop_category" value="EMI" />
              <input type="hidden" name="amount" :value="pdg.amount" />
              <input type="hidden" name="email" :value="pdg.email" />

              <!-- <v-row>
            <v-col cols="12" sm="12"> -->
              <center>
                <div id="allow" style="display: block">
                  <v-btn
                    type="submit"
                    name="submit"
                    color="primary"
                    class="mb-2"
                    >Pay Now</v-btn
                  >
                </div>
              </center>
              <!--  </v-col> -->
              <!-- </v-row> -->
            </form>
          </v-col>
          <v-col cols="12" sm="2" v-if="this.EASEBUZZ">
              <center>
                <form
                  :action="easebuzzurl + '/pay/' + easzetoken"
                  method="post"
                >
                  <v-btn
                    type="submit"
                    name="button"
                    color="primary"
                    class="mb-2"
                    >Pay By easebuzz</v-btn
                  >
                </form>
              </center>
            </v-col>
            <v-col cols="4" sm="4"></v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
const CryptoJS = require("crypto-js");
import "@/assets/css/styles.css";
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";
Vue.use(LoadScript);
export default {
  data: () => ({
    error: "",
    snackbar_msg: "",
    color: "",
    snackbar: false,

    provisionadmission: null,
    program: null,
    year: null,
    academicyear: null,
    category: null,
    checkbox: "check",
    provisionadmissionid: null,
    payable_amount: 0,
    pu_url: "",
    udf_info: [],
    payu: false,
    EASEBUZZ: false,
    pdg: "",
    easebuzzurl: "",
    easzetoken: "",
  }),

  mounted() {
    this.provisionadmissionid = this.decrypt(
      this.$route.query.provisionadmissionid
    );
    this.onLoad();
  },

  methods: {
    checkterms(checkbox) {
      if (checkbox) {
        document.getElementById("allow").style.display = "block";
      } else {
        document.getElementById("allow").style.display = "none";
      }
    },

    onLoad() {
      var data = {
        provisionadmissionid: this.provisionadmissionid,
      };
      axios
        .post("/AppLearnerAdmission/feespaymentVUAdmissionRequest", data)
        .then((res) => {
          if (res.data.status == "200") {
            this.error = "";
            this.provisionadmission = res.data.provisionadmission;
            this.program = res.data.program;
            this.year = res.data.year;
            this.academicyear = res.data.academicyear;
            this.category = res.data.category;
            if (res.data.payu) {
              this.payu = true;
              this.pu_url = res.data.locatorURL;
              this.pdg = res.data.pdg;
              this.udf_info = res.data.udf_fields;
            }
            if (res.data.EASEBUZZ) {
              this.EASEBUZZ = true;
              this.easebuzzurl = res.data.easebuzzredurl;
              this.easzetoken = res.data.easeBuzzurl;
            }

            this.payable_amount = res.data.provisionadmission.payable_amount;
          } else if (res.data.status == "401") {
            this.error = res.data.msg;
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something Went Wrong...");
        })
        .finally(() => {
          // 'use strict';
        });
    },

    decrypt(src) {
      const passphrase = "987654";
      const bytes = CryptoJS.AES.decrypt(src, passphrase);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      return originalText;
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
};
</script>
<style scoped>
.tc-title {
  background-image: linear-gradient(-90deg, #fcc96f, #fcb63c);
  color: #fff;
  font-weight: 700;
  margin: 0px !important;
  padding: 0px !important;
}
.text-left {
  font-size: 14px;
  font-weight: bold;
}
.text {
  color: blue;
  font-weight: 600;
  font-size: 14px;
}
</style>